<script>
export default {
  name: 'Divisor',
  props: {
    marginSize: {
      type: String,
      default: 'medium'
    },
    orientation: {
      type: String,
      default: 'horizontal'
    },
    darker: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getMarginSize () {
      return this.marginSize
    }
  }
}
</script>
<template>
  <div
    class="h-line"
    :class="[{'darker': darker, 'is-vertical': orientation === 'vertical'}, getMarginSize()]"
  />
</template>
<style scoped>
.h-line {
  display: block;
  width: 100%;
  border: 1px solid #707070;
  opacity: .2;
}
.darker {
  border-color: #707070;
}
.h-line.large {
  margin: 60px 0;
}

.h-line.medium {
  margin: 40px 0;
}

.h-line.small {
  margin: 20px 0;
}

.h-line.very-small {
  margin: 6px 0;
}
.h-line.is-vertical {
  width: unset;
}
.h-line.is-vertical.large {
  margin: 0 60px;
}

.h-line.is-vertical.medium {
  margin: 0 40px;
}

.h-line.is-vertical.small {
  margin: 0 20px;
}

.h-line.is-vertical.very-small {
  margin: 0 6px;
}
</style>
